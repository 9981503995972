const theme = {
  // Colours
  primaryColor: '#000',
  textColor: '#111111',
  grey: '#5A5A5A',
  darkGrey: '#343434',
  lightGrey: '#F4F4F4',

  dark: {
    background: '#111111',
    textColor: '#fff',
  },

  // Fonts
  fontSize: '1.8rem',
  fontFamily:
    '"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontFamilyHeading:
    '"Greycliff", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

  // Sizing
  maxContainerWidth: '1440px',

  // Animations
  transitionSpeed: '0.9s',
  transitionSetting: 'cubic-bezier(0.19, 1, 0.22, 1)',

  // Breakpoints
  //media queries
  respondTo: {
    desktopXXL: `min-width: 1600px`,
    desktopXL: `min-width: 1400px`,
    desktopL: `min-width: 1280px`,
    desktop: `min-width: 1024px`,
    tabletL: `min-width: 800px`,
    tablet: `min-width: 481px`,
    mobile: `max-width: 480px`,
  },
};

export default theme;
