import { createGlobalStyle } from 'styled-components';
import GraphikMediumWoff from '../../static/fonts/Graphik-Medium.woff';
import GraphikMediumWoff2 from '../../static/fonts/Graphik-Medium.woff2';
import GraphikRegularWoff from '../../static/fonts/Graphik-Regular.woff';
import GraphikRegularWoff2 from '../../static/fonts/Graphik-Regular.woff2';
import GraphikSemiboldWoff from '../../static/fonts/Graphik-Semibold.woff';
import GraphikSemiboldWoff2 from '../../static/fonts/Graphik-Semibold.woff2';
import GreycliffWoff from '../../static/fonts/GreycliffCF-Heavy.woff';
import GreycliffWoff2 from '../../static/fonts/GreycliffCF-Heavy.woff2';

const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: 'Greycliff';
		src: url(${GreycliffWoff}) format('woff'),
			url(${GreycliffWoff2}) format('woff2');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Graphik';
		src: url(${GraphikRegularWoff}) format('woff'),
			url(${GraphikRegularWoff2}) format('woff2');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Graphik';
		src: url(${GraphikMediumWoff}) format('woff'),
			url(${GraphikMediumWoff2}) format('woff2');
		font-weight: 600;
		font-style: normal;
	}

	@font-face {
		font-family: 'Graphik';
		src: url(${GraphikSemiboldWoff}) format('woff'),
			url(${GraphikSemiboldWoff2}) format('woff2');
		font-weight: 800;
		font-style: normal;
	}

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    padding: 0;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    height: auto;
    width: 100%;
    overflow: auto;
		font-size: 1.8rem;
		line-height: 1.25;
    font-family: ${({ theme }) => theme.fontFamily};
    background: #FFF;
    color: ${({ theme }) => theme.textColor};
		transition: all ${({ theme }) => theme.transitionSpeed} ${({ theme }) => theme.transitionSetting};

		&.theme-dark {
			background: ${({ theme }) => theme.dark.background};
			color: ${({ theme }) => theme.dark.textColor};
		}
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamilyHeading};
		font-weight: normal;
		line-height: 1.1;
  }

  p {
    margin: 0;
    padding: 0;
		line-height: 1.75;
		transition: color ${({ theme }) => theme.transitionSpeed} ${({ theme }) => theme.transitionSetting};

    + p,
		+ ul {
      margin: 1.5rem 0 0;
    }

		.theme-dark & {
			color: ${({ theme }) => theme.dark.textColor};
		}
  }

  a {
    color: inherit;
    text-decoration: none;
		transition: color ${({ theme }) => theme.transitionSpeed} ${({ theme }) => theme.transitionSetting};

		.theme-dark & {
			color: ${({ theme }) => theme.dark.textColor};
		}
	}

	img {
		max-width: 100%;
	}
`;

export default GlobalStyle;
