import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Seo from '../components/Seo';
import GlobalStyle from '../styling/GlobalStyle';
import theme from '../styling/theme';
import Logo from '../components/Logo';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
`;

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Seo
          title="Studio Drieluik. Een digitaal bureau anno nu, met de blik op morgen."
          description="Met specialisaties in design & development helpen wij opdrachtgevers hun plek te vinden in het digitale landschap.          "
        />
        <Wrapper>
          <Logo />
          {children}
        </Wrapper>
      </>
    </ThemeProvider>
  );
};

export default Layout;
