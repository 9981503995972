import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import LogoSVG from '../../static/images/logo.svg';
import Container from './Container';

const StyledContainer = styled(Container)`
  position: relative;
`;

const LogoWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  height: 12.3rem;
  width: 10rem;
  padding-bottom: 4rem;
  background: white;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

  .theme-dark & {
    background: none;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;

const Logo = () => {
  return (
    <Wrapper>
      <StyledContainer>
        <LogoWrapper>
          <SVG src={LogoSVG} />
        </LogoWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default Logo;
